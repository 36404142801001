<template>
  <div class="volunteering" id="my-scrollable-container">
    <MyProfile v-model="profileDrawer"></MyProfile>
    <div class="nav-bar">
      <router-link to="/">
        <FileImage
          :src="getLogo()"
          :useBg="true"
          class="logo"
          v-if="getLogo() != null"
        />

        <div class="logo" v-else></div>
      </router-link>
      <div class="flex-grow-1">
        <!-- <div class="search">
          <button>
            <Icon name="fal fa-search" size="18" />
          </button>
          <input type="text" placeholder="Buscar" />
          <button>
            <Icon name="fal fa-times-circle" size="16" />
          </button>
        </div>-->
      </div>
      <button
        @click="donate"
        v-if="
          volunteering.project &&
            volunteering.project.donationProductList &&
            volunteering.project.donationProductList.length > 0 &&
            !!logged
        "
        class="btn outlined hidden-xs-only mr-3 btn-donate"
      >
        Faça uma doação
      </button>
      <button
        @click.prevent="
          profileDrawer = !profileDrawer;
          $gtag('send', 'event', 'header', 'home', 'Perfil');
        "
        v-if="logged"
        class="hidden-xs-only mr-3"
      >
        <div class="avatar-profile sm">
          <img :src="getUrl()" alt />
        </div>
        <!-- <Icon :name="'uil uil-user-square'" :size="24" /> -->
      </button>
      <button
        @click="showLogin()"
        v-if="!logged"
        class="btn outlined hidden-xs-only mr-3"
      >
        Entrar
      </button>
    </div>
    <!-- <div v-if="!volunteering.id">
      <div class="bg-white pt-4">
        <v-container>
          <v-layout class="banner px-2 flex-wrap">
            <v-flex sm12 md12 pr-md-4 mb-4>
              <h1 class="my-3">Nenhum projeto de voluntariado selecionado</h1>
              <p>Os projetos de voluntariados são destinados a pessoas que desejam doar o seu tempo, seu conhecimento e suas habilidades.</p>
              <p>Caso não tenha selecionado um projeto de voluntariado, volte à campanha tenho certeza de que existe o projeto certo para você!</p>
              <p>Ah claro, nem todo mundo tem todo o tempo do mundo, mas fique tranquilo, há vagas para diversas atividades em diversos horários e dias da semana, escolha uma que se encaixa no seu perfil.</p>
              <p>
                Caso deseje efetuar doações clique no link acima em:
                <a>Faça uma doação</a>
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div> -->
    <div>
      <div class="bg-white pt-4" id="inicio">
        <v-container>
          <v-layout class="banner px-2 flex-wrap">
            <v-flex sm12 md7 pr-md-4 mb-4>
              <v-layout align-center>
                <Icon name="fal fa-map-marker-alt" class="mr-2" size="16" />
                <span>São Paulo, SP, Brasil</span>
              </v-layout>
              <h1 class="my-2">{{ volunteering.fullName }}</h1>
              <p class="text-light">{{ volunteering.metaDescription }}</p>
              <ul class="my-3">
                <li
                  v-for="(tag, index) in volunteeringTags"
                  :key="tag"
                  :class="getTagClass(index)"
                >
                  {{ tag }}
                </li>
              </ul>
              <p class="text-default">
                Beneficiados:
                <strong class="text-primary">{{
                  volunteering.beneficiaries
                }}</strong>
              </p>
              <div class="my-3 subs-box">
                <v-layout align-center flex-wrap>
                  <div class="flex-grow-1">
                    <b>
                      <strong>{{ usersSubscribed }}</strong> inscritos
                    </b>
                  </div>
                  <span>
                    Faltam
                    <strong class="text-orange">{{
                      totalRegistrationsLeft
                    }}</strong>
                    voluntários
                  </span>
                </v-layout>
                <div class="progress">
                  <span
                    class="bg-primary"
                    :style="`width: ${percentageRegistrations}%`"
                  ></span>
                </div>
              </div>
              <v-layout justify-space-between flex-wrap v-if="logged">
                <v-btn
                  round
                  color="btn-secondary"
                  dark
                  v-if="
                    campaignInfos &&
                      campaignInfos.subscribeMoreThanOneTime === false &&
                      hasSubscribedOnVolunteerSlot
                  "
                >
                  <Icon name="fas fa-check-circle" class="mr-2" size="20" />Você
                  Você já possui uma inscrição nesta campanha
                </v-btn>

                <v-btn round color="btn-primary" dark @click="roolup()" v-else>
                  <Icon
                    name="fas fa-check-circle"
                    class="mr-2"
                    size="20"
                  />Quero me inscrever
                </v-btn>
                
                <!-- <v-btn round color="gray4" class="mb-1" dark v-click="addFavorites()">
                  <Icon name="fal fa-heart" class="mr-2" size="20" />Favorito
                </v-btn>-->
              </v-layout>
              <v-layout justify-space-between flex-wrap v-else>
                <v-btn round color="secondary" dark @click="showLogin()">
                  <Icon
                    name="fas fa-check-circle"
                    class="mr-2"
                    size="20"
                  />Inscreva-se
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex sm12 md5 mb-4>
              <div
                class="box-brand"
                v-if="
                  volunteeringFileHero &&
                    volunteeringFileHero.file &&
                    volunteeringFileHero.file.path
                "
              >
                <img :src="volunteeringFileHero.file.path" alt />
                <div class="brand" v-if="volunteeringInstitution != null">
                  <div class="brand-container">
                    <img :src="volunteeringInstitution.file.path" alt />
                    <v-btn
                      @click="donate"
                      v-if="
                        volunteering.project &&
                          volunteering.project.donationProductList &&
                          volunteering.project.donationProductList.length > 0
                      "
                      color="bg-primary"
                      round
                      dark
                      >Faça uma doação</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <scrollactive
        active-class="active"
        class="my-nav menu bg-white px-2 hidden-xs-only display-flex justify-content-center"
      >
        <li>
          <a href="#inicio" class="scrollactive-item">
            <Icon name="fal fa-arrow-up" class="mr-2" size="16" />Início
          </a>
        </li>
        <li>
          <a href="#projeto" class="scrollactive-item">
            <Icon name="fal fa-file-alt" class="mr-2" size="16" />Sobre o
            Projeto
          </a>
        </li>
        <li>
          <a href="#datas" class="scrollactive-item">
            <Icon name="fal fa-calendar-alt" class="mr-2" size="16" />Como você
            pode atuar
          </a>
        </li>
        <li>
          <a href="#localizacao" class="scrollactive-item">
            <Icon
              name="fal fa-map-marker-alt"
              class="mr-2"
              size="16"
            />Localização
          </a>
        </li>
        <li>
          <a href="#depoimentos" class="scrollactive-item">
            <Icon
              name="fal fa-comment-dots"
              class="mr-2"
              size="16"
            />Depoimentos
          </a>
        </li>
      </scrollactive>
      <v-container>
        <div class="app-card" id="projeto">
          <h1 class="app-card-title">Sobre o projeto</h1>
          <v-layout flex-wrap>
            <v-flex md8 pr-md-4 mb-2>
              <div class="project-adv">
                <div v-if="volunteeringActDistance">
                  <Icon
                    name="fal fa-globe-americas"
                    class="mr-3"
                    size="24"
                  />Pode atuar à distancia
                </div>
                <div v-if="volunteeringActPresential">
                  <Icon name="fal fa-users" class="mr-3" size="24" />Pode atuar
                  presencial
                </div>
                <div v-if="volunteeringActPontual">
                  <Icon name="fal fa-calendar" class="mr-3" size="24" />Vagas
                  Pontuais
                </div>
                <div v-if="volunteeringActRecurrency">
                  <Icon name="fal fa-sync" class="mr-3" size="24" />Vagas
                  recorrentes
                </div>
              </div>
              <div v-html="volunteering.about"></div>
            </v-flex>
            <!-- <v-flex md4 mb-4>
              <div class="box-list">
                <div class="box-list-header">Habilidades necessárias</div>
                <div class="py-3">
                  <li>
                    <Icon name="fal fa-check" class="mr-3" size="14" />Agilidade
                  </li>
                  <li>
                    <Icon name="fal fa-check" class="mr-3" size="14" />Artes/Trabalho manual
                  </li>
                  <li>
                    <Icon name="fal fa-check" class="mr-3" size="14" />Computadores/Tecnologia
                  </li>
                  <li>
                    <Icon name="fal fa-check" class="mr-3" size="14" />Comunicação
                  </li>
                  <li>
                    <Icon name="fal fa-check" class="mr-3" size="14" />Gerenciamento
                  </li>
                  <li>
                    <Icon name="fal fa-check" class="mr-3" size="14" />Organização
                  </li>
                </div>
              </div>
            </v-flex>-->
          </v-layout>
        </div>
        <div
          class="app-card"
          id="datas"
          v-if="campaignAttributeOffers && campaignAttributeOffers.length > 0"
        >
          <h1 class="app-card-title">Como você pode atuar</h1>
          <v-layout mt-5 
            class="row justify-center"
            :key="volunteeringCardsKey">
            <CardAttributeOffer
              v-for="attributeOffer in campaignAttributeOffers"
              :info="infoCard"
              :key="attributeOffer.id"
              :attributeOffer="attributeOffer"
              @selectOffer="openModalAttributeOfferSubscribe"
              :volunteeringAttributeOfferId="attributeOffer.id"
              :VolunteerSlot="VolunteerSlot"
              :multipleSlot="multipleSlots"
              :hasSubscribedOnVolunteerSlot="hasSubscribedOnVolunteerSlot"
              :institution="volunteeringInstitution"
            />
          </v-layout>
          <div>
            <ModalAttributeOfferSubscribe
              :attributeOffer="volunteeringAttributeOfferSelected"
              :registrationOffers="volunteeringUserRegistrationOffers"
              :volunteering="volunteeringSelected"
              :dialog="volunteeringDialog"
              :isDeleted="isDeleted"
              @close="volunteeringDialog = false"
            />
          </div>
        </div>
      </v-container>

      <!--NEW MOSAIC-->
      <div class="bloco image-grid bg-theme-white">
        <vue-easy-lightbox
          :visible="galleryLightboxVisible"
          :imgs="getGalleryLightboxImgs()"
          :index="galleryLightboxIndex"
          @hide="galleryLightboxVisible = false"
        ></vue-easy-lightbox>
        <div class="wrapper">
          <!-- quando houver fotos -->
          <template>
            <div
              class="item"
              v-for="(volunteeringFile, index) in volunteeringFiles"
              :key="'volunteeringPhoto' + index"
            >
              <div class="tools">
                <a
                  href="javascript:;"
                  class="fal fa-search-plus"
                  @click="openGalleryLightbox(index)"
                ></a>
                <!-- <a href="javascript:;" class="fal fa-trash"></a> -->
              </div>
              <FileImage
                :src="volunteeringFile.file.path"
                effects="w_700,q_80"
              />
              <!-- <img src="https://source.unsplash.com/featured/?water" alt> -->
            </div>
          </template>
        </div>
      </div>
      <!--<div v-if="volunteeringFiles.length" class="full-images d-flex justify-center align-center">
        <slick :options="slickOptions">
          <template v-for="(volunteeringFile, index) in volunteeringFiles" >
            <img :src="volunteeringFile.file.path" :key="'volunteer'+index"/>
          </template>
        </slick>
      </div>-->

      <v-container>
        <v-row>
          <div class="app-card mt-0 flex-wrap" id="localizacao">
            <div class="map-body">
              <h1 class="app-card-title">Abrangência</h1>
              <div class="py-1">
                <div
                  style="cursor: pointer;"
                  class="mt-3 display-flex"
                  :key="index"
                  v-for="(m, index) in volunteering.volunteeringCoverages"
                  @click="volunteeringMapCenter = getPosition(m)"
                >
                  <i class="icon-map-marker"></i>
                  <span>{{ m.name }}</span>
                </div>
              </div>
            </div>
            <div class="map">
              <GmapMap
                :center="volunteeringMapCenter"
                :zoom="10"
                map-type-id="terrain"
                style="width: 100%; height: 100%"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in volunteering.volunteeringCoverages"
                  :position="getPosition(m)"
                  :clickable="true"
                  :draggable="true"
                  icon="/map-marker-alt.png"
                  @click="volunteeringMapCenter = getPosition(m)"
                />
              </GmapMap>
            </div>
          </div>
        </v-row>
        <div
          class="app-card"
          id="depoimentos"
          v-if="
            volunteering.id && volunteering.volunteeringTestimonies.length > 0
          "
        >
          <h1 class="app-card-title">Depoimentos</h1>
          <slick :options="slickOptionsDepoimentos">
            <div
              style="cursor: pointer;"
              class="item"
              :key="index"
              v-for="(testimony, index) in volunteering.volunteeringTestimonies"
              @click="selectTestimony(testimony)"
            >
              <div class="avatar">
                <img :src="testimony.file.path" alt />
              </div>
              <h4>{{ testimony.name }}</h4>
              <p class="mb-0">
                <small>{{ testimony.city }}, {{ testimony.uf }}</small>
              </p>
              <p class="text-blue">
                <b>{{ testimony.role }}</b>
              </p>
              <p class="mt-3">{{ testimony.testimony }}</p>
            </div>
          </slick>
        </div>
        <div class="app-card" id="ong" v-if="volunteeringInstitution != null">
          <h1 class="app-card-title">Sobre</h1>
          <v-layout class="py-3" align-start>
            <v-flex sm8 mb-3>
              <img :src="volunteeringInstitution.file.path" width="110" />
              <h4 class="text-blue">
                {{ volunteeringInstitution.entity.name }}
              </h4>
              <div v-html="volunteeringInstitution.mission"></div>
              <!-- <p>
                Com o intuito de proteger vidas e orientar o maior número de
                pessoas em atividades de turismo e lazer, a Férias Vivas busca
                soluções em diversas frentes:
              </p>
              <p>
                - Qualificação de profissionais de turismo com projeto de
                multiplicadores em pólos turísticos.
              </p>
              <p>
                - Disseminação de uma cultura de segurança entre viajantes por
                meio de orientação gratuita no site, palestras, participação em
                eventos e impressão de material didático.
              </p>
              <p>- Mobilização e integração de diversos setores da sociedade.</p>
              <p>
                - Contribuição para a participação de todos os setores do poder
                (Federal, Estadual e Municipal), por meio da participação em
                grupos de trabalho e projetos de desenvolvimento do setor.
              </p>
              <p>- Contribuição para a evolução da normatização no âmbito da ABNT</p>
              <p>
                - Associação Brasileira de Normas Técnicas como coordenador da
                Comissão de Estudo de Sistemas de Gestão da Segurança do Comitê
                Brasileiro de Turismo.
              </p>
              <p>
                - Contribuição de legislação adequada com vistas à melhoria das
                condições do turismo através de análise e influencia na Lei
                Geral do Turismo.Encontre oportunidades abertas para voluntários
                em nossa lista de ATOS ABERTOS e cadastre-se!
              </p>-->
            </v-flex>
            <v-flex sm4 class="contato">
              <h4>Contato</h4>
              <div class="pa-3">
                <v-layout>
                  <Icon name="fal fa-map-marker-alt" size="16" class="mr-2" />
                  <p>
                    {{ getAddressText(volunteeringInstitution.entity.address) }}
                  </p>
                </v-layout>
                <!-- <v-layout py-3>
                  <Icon name="fal fa-envelope" size="16" class="mr-2" />
                  <p>
                    <a href="mailto:contato@feriasvivas.org.br">contato@feriasvivas.org.br</a>
                  </p>
                </v-layout>-->
                <v-layout>
                  <Icon name="fal fa-globe" size="16" class="mr-2" />
                  <p>
                    <a :href="volunteeringInstitution.site">{{
                      volunteeringInstitution.site
                    }}</a>
                  </p>
                </v-layout>
              </div>
              <v-layout class="brands pa-3">
                <a v-if="volunteeringInstitution.facebook" :href="volunteeringInstitution.facebook">
                  <Icon name="fab fa-facebook" size="24" class="mr-2" />
                </a>
                <!-- <a href="#">
                  <Icon name="fab fa-linkedin" size="24" class="mr-2" />
                </a>-->
                <a v-if="volunteeringInstitution.videoURL" :href="volunteeringInstitution.videoURL">
                  <Icon name="fab fa-youtube" size="24" class="mr-2" />
                </a>
                <a v-if="volunteeringInstitution.instagram" :href="volunteeringInstitution.instagram">
                  <Icon name="fab fa-instagram" size="24" class="mr-2" />
                </a>
                <a v-if="volunteeringInstitution.twitter" :href="volunteeringInstitution.twitter">
                  <Icon name="fab fa-twitter" size="24" class="mr-2" />
                </a>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <div id="myModalMosaic" class="modalMosaic">
          <span class="close-mosaic" @click="desactiveModal()">&times;</span>
          <img class="h-80 w-80 modal-content-mosaic" id="imgModal" />
        </div>
      </v-container>
      <!-- <ModalAttributeOfferSubscribe
        :attributeOffer="volunteeringAttributeOfferSelected"
        :registrationOffers="volunteeringUserRegistrationOffers"
        :volunteering="volunteering"
        :dialog="volunteeringDialog"
        :isDeleted="isDeleted"
        @close="volunteeringDialog = false"
        :info="infoCard"
      /> -->
    </div>
    <footer>
      <p>Copyright ©2021 - Abrace uma causa</p>
      <p>Todos os direitos reservados.</p>
    </footer>

    <ModalSimplifiedCheckout
      :key="modalDonationKey"
      :show="showSimplifiedCheckout"
      @close="showSimplifiedCheckout = false"
      :projectId="volunteering.projectId"
    ></ModalSimplifiedCheckout>
  </div>
</template>

<script>
import ModalAttributeOfferSubscribe from "@/views/app/campaign/modal/ModalAttributeOfferSubscribe.vue";
import CardAttributeOffer from "@/components/CardAttributeOffer.vue";
import ModalSimplifiedCheckout from "@/views/app/campaign/modal/ModalSimplifiedCheckout.vue";
import MyProfile from "@/components/MyProfile.vue";
import VolunteeringService from "@/scripts/services/volunteering.service";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";
import InstitutionService from "@/scripts/services/institution.service";
import LoginService from "@/scripts/services/login.service";
import CampaignService from "@/scripts/services/campaign.service";
import PersonService from "@/scripts/services/person.service.js";
import { mapGetters, mapActions } from "vuex";
import { Routes } from "@/scripts/models/enums/routes.enum";
import Slick from "vue-slick";
import FileImage from "@/components/FileImage.vue";
import md5 from "crypto-js/md5"

export default {
  components: {
    ModalAttributeOfferSubscribe,
    CardAttributeOffer,
    ModalSimplifiedCheckout,
    FileImage,
    MyProfile
  },
  data() {
    return {
      dialog: false,
      profileDrawer: false,
      showSimplifiedCheckout: false,
      volunteeringService: new VolunteeringService(),
      volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      personService: new PersonService(),
      institutionService: new InstitutionService(),
      campaignService: new CampaignService(),
      loginService: new LoginService(),
      modalDonationKey: 0,
      hasSubscribedOnVolunteerSlot: false,
      campaignAttributeOffers: null,
      VolunteerSlot: null,
      galleryLightboxIndex: 0,
      infoCard: null,
      galleryLightboxVisible: false,
      slickOptions: {
        slidesToShow: 3,
        dots: false,
        variableWidth: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1339,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 365,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsDepoimentos: {
        slidesToShow: 3,
        dots: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1339,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 365,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      volunteeringId: 0,
      volunteerings: [],
      volunteering: null,
      volunteeringTags: [],
      volunteeringTestimonySelected: {},
      volunteeringMapCenter: { lat: -23.55052, lng: -46.633308 },
      volunteeringAttributeOffers: [],
      volunteeringAttributeOfferSelected: null,
      volunteeringFiles: [],
      volunteeringFileHero: null,
      volunteeringActPontual: false,
      volunteeringActRecurrency: false,
      volunteeringActPresential: false,
      volunteeringActDistance: false,
      volunteeringInstitution: null,
      volunteeringUserRegistrationOffers: [],
      volunteeringTotalOfferAmount: 0,
      volunteeringTotalRegistrationsOffers: 0,
      campaignInfos: null,
      infoCard: null,
      volunteeringDialog: false,
      multipleSlots: false,
      isDeleted: false,
      entityId: null,
      userInformation: null,
      volunteeringSelected: {},
      totalSlots: 0,
      usersSubscribed: 0,
      volunteeringCardsKey: 0
    };
  },
  computed: {
    ...mapGetters(["logged", "landingPageOptions", "campaignLandingPageConfig"]),
    totalRegistrationsLeft() {
      return this.totalSlots - this.usersSubscribed;
    },
    percentageRegistrations() {
      if (this.totalSlots == 0) return 0;

      var result = (100 * this.usersSubscribed) / this.totalSlots;

      return result;
    },
  },
  watch: {
    volunteering(volunteering, old) {
      if (volunteering.tags) {
        this.volunteeringTags = volunteering.tags
          .split("#")
          .filter((tag) => tag)
          .map((tag) => tag.trim());
      }

      if (
        volunteering.volunteeringTestimonies &&
        volunteering.volunteeringTestimonies.length > 0
      ) {
        this.volunteeringTestimonySelected =
          volunteering.volunteeringTestimonies[0];
      }

      if (
        volunteering.volunteeringCoverages &&
        volunteering.volunteeringCoverages.length > 0
      ) {
        this.volunteeringMapCenter = this.getPosition(
          volunteering.volunteeringCoverages[0]
        );
      }
    },
    async logged(newValue, oldValue) {
      if (!oldValue && newValue) {
        await this.userHasRegistrations();
        await this.loadCampaignBySubdomain();
        this.openLastModalAttributeOfferSubscribe();
      }
    },
  },
  async mounted() {
    await this.setLandingPageConfig()
    this.volunteeringId = this.$route.query.volunteeringId;
    await this.loginService.verifyToken((data) => {
      return this.setLogged(data);
    });
    
    await this.getUserInformation();
    await this.loadCampaignData();
    await this.loadVolunteering();
    await this.loadVolunteeringInstitution();
    await this.loadVolunteeringFiles();
    await this.userHasRegistrations();
    await this.loadCampaignBySubdomain();
    await this.getSubscribers();
  },
  methods: {
  ...mapActions(["setLogged", "setLandingPageConfig"]),
    showLogin() {
      this.$store.commit("SHOW_LOGIN");
    },
    getLogo() {
      if (
        this.campaignLandingPageConfig != null &&
        this.campaignLandingPageConfig.campaign != null &&
        this.campaignLandingPageConfig.campaign.file != null
      ) {
        return this.campaignLandingPageConfig.campaign.file.path;
      }
      return null;
    },
    toggleProfileDrawer: function () {
      this.profileDrawer = !this.profileDrawer;
    },
    openLastModalAttributeOfferSubscribe() {
      if (this.volunteeringAttributeOfferSelected)
        this.openModalAttributeOfferSubscribe(
          this.volunteeringAttributeOfferSelected
        );
    },
    async reloadCards() {
      await this.loadVolunteering();
      this.volunteeringCardsKey += 1;
    },
    openModalAttributeOfferSubscribe(attributeOffer, isDeleted = false) {
      this.volunteeringAttributeOfferSelected = attributeOffer;
      this.infoCard = attributeOffer;
      if (!this.logged) {
        this.$store.commit("SHOW_LOGIN");
        return;
      }

      if (isDeleted) this.isDeleted = true;
      else this.isDeleted = false;
      
      this.volunteeringRegistrationOfferService
        .findUserRegistrationOffersToAttributeOffer(attributeOffer.id)
        .then((data) => {
          for (const item of data) {
            if (!item.isDeleted)
              this.volunteeringUserRegistrationOffers.push(item);
          }

          this.volunteeringDialog = true;
        });


    },
    async getUserInformation() {
      this.entityId = this.$route.query.entityId;
      if (this.logged) {
        return await this.personService.getLoggedPersonUser().then((data) => {
          this.userInformation = data;
        });
      }
    },
    getUrl() {
        let hex = "3377b4";
        let rgba = getComputedStyle(document.documentElement).getPropertyValue("--theme-primaria").split(",");
        
        if (rgba != '') {
        hex = `${(
            (1 << 24) +
            (parseInt(rgba[0]) << 16) +
            (parseInt(rgba[1]) << 8) +
            parseInt(rgba[2])
        )
            .toString(16)
            .slice(1)}`;
        }

        let email = md5(this.userInformation?.email);
        let name;
        
        if(this.userInformation != null && this.userInformation.cnpj){
        name = this.userInformation?.socialReason;
        }else{
        name = this.userInformation?.name;
        }

        let url = `https://www.gravatar.com/avatar/${email}?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F${name}/128/${hex}/ffffff`;
        return url;
    },
    closeModal() {
      this.dialog = false;
    },
    async loadVolunteering(){
      const userVolunteering = []
      let allVolunteering = []
      const idsSubscribed = []
      let volunteeringExists = []

      await this.volunteeringService.findById(this.volunteeringId).then((r) => {
        this.volunteering = r;
      });

      if (this.logged && this.userInformation !== null) {
          await this.volunteeringRegistrationOfferService
            .findUserRegistrationOffersbyUserId(this.userInformation.userId)
            .then(data => {
            for (const volunteer of data) {
              volunteer.userHasSubscription = true
              idsSubscribed.push(volunteer.id)
              userVolunteering.push(volunteer)
            }
          });

        
        await this.volunteeringAttributeOfferService.listVolunteeringAttributeOfferByCampaign().then(async (data) => {
          for (const volunteer of data) {
            volunteer.userHasSubscription = false
            allVolunteering.push(volunteer)
          }
        })

        // Separe information cards that user already has subscription ans pass to another array
        for (const id of idsSubscribed) {
          let item = allVolunteering.filter((e) => {
            return e.id === id
          })

          if (volunteeringExists.indexOf(item[0]) === -1) volunteeringExists.push(item[0])
        }

        // Remove information cards from main array that user already has subcription
        for (const id of idsSubscribed) {
          allVolunteering = allVolunteering.filter((e) => {
            return e.id !== id
          })
        }

        // Add updated information to cards separated
        for (const volunteer of userVolunteering) {
          volunteeringExists.map((e) => {
            if (e.id === volunteer.id) {
              e.isDeleted = volunteer.isDeleted
              e.userHasSubscription = volunteer.userHasSubscription
            }
          })
        }
        allVolunteering = allVolunteering.concat(volunteeringExists) // Concat modified cards array to main array
      } else {
        await this.volunteeringAttributeOfferService.listVolunteeringAttributeOfferByCampaign().then(async (data) => {
          for (const volunteer of data) {
            volunteer.userHasSubscription = false
            allVolunteering.push(volunteer)
          }
        })
      }
      this.volunteeringAttributeOffers = allVolunteering
      this.volunteeringAttributeOffersBackup = this.volunteeringAttributeOffers
      this.userAlreadyHasRegistrations = await this.userHasRegistrations()
      return this.volunteering;
    },
    async loadVolunteeringFiles() {
      return this.volunteeringService
        .listVolunteeringFiles(this.volunteeringId)
        .then((data) => {
          this.separeFiles(data);
        });
    },
    async loadVolunteeringInstitution() {
      if (!this.volunteeringId) return;

      return this.volunteeringService
        .findInstitutionByVolunteeringId(this.volunteeringId)
        .then((data) => (this.volunteeringInstitution = data));
    },
    loadVolunteeringTestimonies() {
      if (this.volunteering.volunteeringTestimonies.length > 5)
        this.slickOptionsDepoimentos.slidesToShow = 5;
      else
        this.slickOptionsDepoimentos.slidesToShow = this.volunteering.volunteeringTestimonies.length;

      if (this.volunteering.volunteeringTestimonies.length > 3)
        this.slickOptionsDepoimentos.responsive[0].settings.slidesToShow = 3;
      else
        this.slickOptionsDepoimentos.responsive[0].settings.slidesToShow = this.volunteering.volunteeringTestimonies.length;

      if (this.volunteering.volunteeringTestimonies.length > 2)
        this.slickOptionsDepoimentos.responsive[1].settings.slidesToShow = 2;
      else
        this.slickOptionsDepoimentos.responsive[1].settings.slidesToShow = this.volunteering.volunteeringTestimonies.length;
    },
    separeFiles(volunteeringFiles) {
      volunteeringFiles.forEach((volunteeringFile) => {
        if (volunteeringFile.isHero)
          this.volunteeringFileHero = volunteeringFile;
        else if (!volunteeringFile.isFaviconFile)
          this.volunteeringFiles.push(volunteeringFile);
      });
    },
    donate() {
      this.showSimplifiedCheckout = true;
    },
    getTagClass(index) {
      index++;
      if (index % 2 == 0) return "pill-blue";
      else if (index % 3 == 0) return "pill-red";
      else return "pill-green";
    },
    addFavorites() {},
    roolup() {
      const el = document.querySelector("#datas");
      el && el.scrollIntoView();
    },
    getPosition(volunteeringCoverage) {
      if (volunteeringCoverage)
        return {
          lat: Number(`${volunteeringCoverage.lat}`),
          lng: Number(`${volunteeringCoverage.long}`),
        };

      return { lat: -23.55052, lng: -46.633308 };
    },
    selectTestimony(testimony) {
      this.volunteeringTestimonySelected = testimony;
    },
    getAddressText(address) {
      if (!address) return "";

      var addressText = "";

      addressText = this.addAtAddressText(addressText, address.street);
      addressText = this.addAtAddressText(addressText, address.number);
      addressText = this.addAtAddressText(
        addressText,
        address.neighborhood,
        " - "
      );
      addressText = this.addAtAddressText(addressText, address.city);
      addressText = this.addAtAddressText(addressText, address.state, " - ");
      addressText = this.addAtAddressText(addressText, address.postalCode);
      addressText = this.addAtAddressText(addressText, address.country);

      return addressText;
    },
    addAtAddressText(addressText, text, separator) {
      if (!text) return addressText;
      if (!separator) separator = ", ";

      if (!text && text.trim().length == 0) {
        return addressText;
      }

      if (addressText.length > 0) return addressText + separator + text;

      return text;
    },
    activateModal(event) {
      const modal = document.getElementById("myModalMosaic");
      const srcImage = event.target.src;
      const modalImg = document.getElementById("imgModal");
      modal.style.display = "block";
      modalImg.src = srcImage;
    },
    desactiveModal() {
      const element = document.getElementById("myModalMosaic");
      element.style.display = "none";
    },
    async userHasRegistrations() {
      let registrations = 0;
      let registration = [];
      let attributeOfferIds = [];

      if (this.campaignAttributeOffers) {
        for (const attributeOffer of this.campaignAttributeOffers) {
          await this.volunteeringRegistrationOfferService
            .findUserRegistrationOffersToAttributeOffer(attributeOffer.id)
            .then((data) => {
              if (!data) {
                return;
              }

              for (const item of data) {
                if (!item.isDeleted) {
                  registration.push(item);
                  registrations++;
                }
              }
            });
        }

        registration.forEach((r) => {
          if (r.volunteeringRecurrentType)
            attributeOfferIds.push(
              r.volunteeringRecurrentType.volunteeringAttributeOfferId
            );
          if (r.volunteeringPontualType)
            attributeOfferIds.push(
              r.volunteeringPontualType.volunteeringAttributeOfferId
            );
        });

        this.VolunteerSlot = attributeOfferIds;

        if (registrations > 0) this.hasSubscribedOnVolunteerSlot = true;
        else this.hasSubscribedOnVolunteerSlot = false;

        return this.hasSubscribedOnVolunteerSlot;
      }
    },
    async loadCampaignData() {
      if (!this.$route.query.volunteeringId) return;
      return await this.volunteeringAttributeOfferService
        .findByVolunteeringId(this.$route.query.volunteeringId)
        .then(async (data) => {
          this.campaignAttributeOffers = data;
        });
    },
    async loadCampaignBySubdomain() {
      await this.campaignService.findBySubdomain().then((res) => {
        this.campaignInfos = res;

        if (this.campaignInfos.subscribeMoreThanOneTime)
          this.multipleSlots = true;
        else this.multipleSlots = false;
      });
    },
    async getSubscribers() {
      new Promise((res) => {
        for (const offer of this.campaignAttributeOffers) {
          if (!offer.registrationAmount)
            this.volunteeringRegistrationOfferService
              .findTotalRegistrationAmountByVolunteeringAttributeOfferId(
                offer.id
              )
              .then((data) => {
                this.usersSubscribed += data;
              });
          this.totalSlots += offer.totalOfferAmount;
        }
        res([this.totalSlots, this.usersSubscribed]);
      });
    },
    openGalleryLightbox(index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (this.volunteeringFiles.length === 0) return [];
      for (let i = 0; i < this.volunteeringFiles.length; i++) {
        if (
          this.volunteeringFiles[i] != null &&
          this.volunteeringFiles[i].file != null
        )
          imgs.push(this.volunteeringFiles[i].file.path);
      }
      return imgs;
    },
  },
};
</script>

<style></style>
