<template>
  <div v-if="attributeOffer != null && volunteering != null">
    <v-dialog v-model="dialog" scrollable max-width="480px" content-class="volunteering" persistent>
      <v-card v-if="step == 1 && (!userAlreadyHasRegistrationOffers || isDeleted)">
        <v-card-text>
          <div>
            <img :src="attributeOffer.file ? attributeOffer.file.path : ''" alt class="volunteering-img" />
            <div class="bg-gray-light radius-0 py-1 px-3">
              <v-layout align-center justify-space-between flex-wrap>
                <strong>0/{{ attributeOffer.totalOfferAmount }}</strong>
              </v-layout>
            </div>
            <div class="box-list box-list-modal">
              <div class="box-list-body">
                <h4>{{ attributeOffer.offerName }}</h4>
                <v-layout
                  align-start
                  mb-3
                  d-flex 
                  justify-content-center 
                  align-center 
                  flex-direction-row
                  v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
                >
                  <p class="mb-0">
                    Ao se inscrever nesta vaga o voluntário se compromete a se
                    voluntariar até:
                  </p>
                  <div
                    class="subs-box"
                    v-for="recurrentType in attributeOffer.volunteeringRecurrentTypes"
                    :key="recurrentType.id"
                  >
                    <b>{{ getRecurrentLimitDateText(recurrentType) }}</b>
                  </div>
                </v-layout>
                <v-divider></v-divider>
                <div class="my-3">
                  <h6>Função</h6>
                  <p class="body-2">{{ attributeOffer.function }}</p>
                </div>
                <v-divider></v-divider>
                <div class="my-3">
                  <h6>pré-requisitos</h6>
                  <p class="body-2" v-html="attributeOffer.preRequisites"></p>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="pa-3 caption">
                <div class="checkbox mb-3">
                  <v-checkbox
                    label="Estou de acordo com os pré-requisitos da vaga."
                    v-model="acceptPreRequirements"
                  />
                  <!-- <input type="checkbox" id="vaga" v-model="acceptPreRequirements" />
                  <label for="vaga">
                    <Icon name="fas fa-check-circle" size="20" />
                    <Icon name="fal fa-circle" size="20" />
                    <span>Estou de acordo com os pré-requisitos da vaga.</span>
                  </label> -->
                </div>
                <div class="checkbox">
                    <v-checkbox
                      label="Estou de acordo com os termos"
                      v-model="acceptConsentTerm"
                    />
                  
                  <!-- <input type="checkbox" id="termos" v-model="acceptConsentTerm"/>
                  <label for="termos">
                    <Icon name="fas fa-check-circle" size="20" />
                    <Icon name="fal fa-circle" size="20" />
                    <span>
                      Estou de acordo com os
                      <a @click="consentTerm(true)">termos</a>.
                    </span>
                  </label> -->
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-space-between>
            <v-btn flat round color="white" class="btn-gray text-uppercase" @click="close">
              <Icon name="fas fa-arrow-left" class="mr-2" />Voltar
            </v-btn>
            <v-btn flat round color="white" class="btn-blue text-uppercase" @click="next">
              Continuar inscrição
              <Icon name="fas fa-arrow-right" class="ml-2" />
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card
        v-else-if="step == 2 && attributeOffer.volunteeringType.name == 'Recorrente' && (!userAlreadyHasRegistrationOffers  || isDeleted)"
      >
        <template v-for="recurrentType in attributeOffer.volunteeringRecurrentTypes">
          <v-card-title>
            <div class="box-list box-list-modal">
              <h4>{{ attributeOffer.offerName }}</h4>
              <p>Selecione no calendário abaixo
                <b
                  class="text-blue2"
                >{{ getRecurrentWeekHoursText(recurrentType) }}</b>
                que deseja disponibilizar
                para esta vaga:
              </p>
              <div class="text-xs-right">
                <h5 class="fs-16 text-light">
                  Horas selecionadas
                  <b class="ml-3 text-primary">{{ total }}h</b>
                </h5>
                <p>
                  <small>mínimo {{ attributeOffer.volunteeringRecurrentTypes[0].minHoursDedication }}h, máximo {{ attributeOffer.volunteeringRecurrentTypes[0].maxHoursDedication }}h</small>
                </p>
              </div>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="radius-0 ma-0">
            <div class="pa-1">
              <table class="schedule">
                <thead>
                    <tr>
                    <th class="time"></th>
                    <th v-for="week in weekDays">{{ week.abbr }}</th>
                    </tr>
                </thead>
                <tr v-for="time in times" :key="time.index">
                  <td class="time">{{ time.hour }}</td>
                  <td v-for="week in weekDays">
                    <input
                      type="checkbox"
                      :disabled="week.isDisabled || !(week.initialTime <= time.index && week.finalTime > time.index)"
                      :value="{
                        volunteeringAttributeOfferId: attributeOffer.id,
                        volunteeringRecurrentTypeId: recurrentType.id,
                        time: time.hour,
                        weekDay: week.weekDayName
                      }"
                      v-model="selectedRecurrentTypes"
                      @click="checkMaxHours"
                      :id="week.weekDayName + time.index"
                    />
                    
                    <label :for="week.weekDayName + time.index"
                    style="display: flex; justify-content: center; align-items: center;"
                    :style="[week.isDisabled || !(week.initialTime <= time.index && week.finalTime > time.index) ? {'background-color': 'rgba(var(--theme-fundo))'} : {}]"
                    >
                    <i :for="week.weekDayName + time.index" style="color: white"
                    :class="(week.isDisabled || !(week.initialTime <= time.index && week.finalTime > time.index)) ? 'fal fa-minus' : 'fas fa-check'"></i>
                  </label>
                  </td>
                </tr>
              </table>
            </div>
          </v-card-text>
        </template>
        <v-card-actions>
          <v-layout justify-space-between>
            <v-btn flat round color="white" class="btn-gray text-uppercase" @click="prev">
              <Icon name="fas fa-arrow-left" class="mr-2" />Voltar
            </v-btn>
            <v-btn flat round color="white" class="btn-blue text-uppercase" @click="next">
              Continuar inscrição
              <Icon name="fas fa-arrow-right" class="ml-2" />
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card
        v-else-if="step == 2 && attributeOffer.volunteeringType.name == 'Pontual' && (!userAlreadyHasRegistrationOffers  || isDeleted)"
      >
        <v-card-title>
          <div class="box-list box-list-modal">
            <h4>{{ attributeOffer.offerName }}</h4>
            <div>Selecione no calendário abaixo as datas que deseja disponibilizar para esta vaga:</div>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        
        <v-card-text class="bg-gray-light radius-0 box-list box-list-modal p-0">
          <div class="box-list-body">
            <div
              class="hours align-center"
              v-for="pontualType in attributeOffer.volunteeringPontualTypes"
              :key="pontualType.id"
            >
              <div class="date">
                <span>{{ getPontualTypeMonth(pontualType.volunteeringDate) }}</span>
                <b>{{ getPontualTypeDay(pontualType.volunteeringDate) }}</b>
              </div>
              <div class="mx-4 flex-grow-1">
                <h5
                  class="fs-16 text-primary"
                >{{ getLocaleAwareDate(pontualType.volunteeringDate) }}</h5>
                <p>
                  <b>{{ getPontualTypeWeekDay(pontualType.volunteeringDate) }} - {{ getDate(pontualType.initialTime) }} às {{ getDate(pontualType.finalTime) }}</b>
                </p>
              </div>
              <div class="checkbox">
                <input
                  type="checkbox"
                  name="date"
                  :id="pontualType.id"
                  :value="pontualType.volunteeringDate"
                  v-model="pontualType.selected"
                  @change="selectPontualType(pontualType)"
                />
                <label :for="pontualType.id">
                  <Icon name="fas fa-check-circle" size="20" />
                  <Icon name="fal fa-circle" size="20" />
                </label>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-space-between>
            <v-btn flat round color="white" class="btn-gray text-uppercase" @click="prev">
              <Icon name="fas fa-arrow-left" class="mr-2" />Voltar
            </v-btn>
            <v-btn flat round color="white" class="btn-blue text-uppercase" @click="next">
              Continuar inscrição
              <Icon name="fas fa-arrow-right" class="ml-2" />
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="step == 3 || userAlreadyHasRegistrationOffers">
        <v-card-title>
          <div class="box-list box-list-modal text-primary">
            <h4 class="mb-0">Inscrição concluída</h4>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="pa-3 w100">
            <h4 class="text-primary">Parabéns!</h4>
            <p class="mt-1">Você concluiu a sua inscrição com sucesso.</p>
            <div class="box-list w100 pa-3">
              <div class="display-flex">
                <img v-if="Object.keys(volunteering).indexOf(volunteeringAttributeOffers) !== -1" :src="volunteering.volunteeringAttributeOffers[0].file.path" class="radius-8" width="150" />
                <img v-else src="" alt="">
                <div>
                  <h3 class="title font-weight-bold">{{ volunteering.fullName }}</h3>
                  <h4 class="subheading pt-2">{{ attributeOffer.offerName }}</h4>
                </div>
              </div>
              <p class="mt-2 mb-0 body-2">{{ attributeOffer.function }}</p>
              <div
                class="display-flex mt-2"
                v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
              >
                <div class="subs-box">
                  <b>Voluntáriado até: {{ getRecurrentLimitDateText(attributeOffer.volunteeringRecurrentTypes[0]) }}</b>
                </div>
              </div>
            </div>
            <div class="box-agenda" v-if="attributeOffer.volunteeringType.name == 'Pontual'">
              <p class="body-2">
                Se você possui agenda no Google, clique no botão abaixo para
                marcar sua inscrição no voluntariado em sua agenda:
              </p>
              <template v-for="registration in selectedRegistrationOffers">
                <p>{{ getPontualTypeDateTimeText(registration.volunteeringPontualType) }}</p>
                <p>
                  <a
                    class="button"
                    target="_blank"
                    :href="createGoogleCalendarUrlPontual(registration)"
                  >
                    Marcar no
                    <b>Google Agenda</b>
                    <Icon name="fal fa-calendar-star" />
                  </a>
                </p>
              </template>
            </div>
            <div class="box-agenda" v-if="attributeOffer.volunteeringType.name == 'Recorrente'">
              <p>
                Se você possui agenda no Google, clique no botão abaixo para
                marcar sua inscrição no voluntariado em sua agenda:
              </p>
              <template v-for="gData in googleCalendarData">
                <hr />
                <p>Dias da Semana: {{ gData.weekDays }}</p>
                <p>Horário: das {{ gData.initialTime }} às {{ gData.finalTime }}</p>
                <p>
                  <a class="button" target="_blank" :href="gData.url">
                    Marcar no
                    <b>Google Agenda</b>
                    <Icon name="fal fa-calendar-star" />
                  </a>
                </p>
              </template>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-space-between>
            <v-btn flat round color="white" class="btn-gray text-uppercase" @click="close">Fechar</v-btn>
            <v-btn
              flat
              round
              color="white"
              class="btn-light text-uppercase"
              @click="print(true)"
              v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
            >
              <Icon name="fas fa-calendar-alt" size="20" class="mr-2" />Imprimir calendário
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalAttributeOfferSubscribeCalendar
      :attributeOffer="selectedAttributeOffer"
      :selectedRegistrationOffers="selectedRegistrationOffers"
      :volunteering="volunteering"
      :times="times"
      :recurrentType="recurrentType"
      :dialog="printscreen"
      @onClose="print"
    ></ModalAttributeOfferSubscribeCalendar>
    <ConsentTerms
      v-model="printTerms" 
      :info="attributeOffer"
      @close="printTerms = false"
      @confirmTerms="printTerms = false"
      :consentTerms="attributeOffer.consentTerm"
    />

    <v-snackbar
      v-model="showMessage"
      color="default"
      :timeout="3000"
      vertical="vertical"
      :bottom="false"
      :top="true"
      :left="true"
    >
      {{ message }}
      <v-btn dark flat @click="showMessage = false">Fechar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

import _ from 'lodash';
import moment from 'moment';
import CommonHelper from '@/scripts/helpers/common.helper';
import UIDHelper from '@/scripts/helpers/uid.helper';
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";
import Modal from "@/components/Modal.vue";
import ModalAttributeOfferConsentTermsVue from "@/views/app/campaign/modal/ModalAttributeOfferConsentTerms.vue";
import ModalAttributeOfferSubscribeCalendar from "@/views/app/campaign/modal/ModalAttributeOfferSubscribeCalendar.vue";
import ConsentTerms from "@/components/ConsentTerms.vue";
import CampaignService from "@/scripts/services/campaign.service";
import VolunteeringService from "@/scripts/services/volunteering.service";

export default {
  components: {
    Modal,
    ConsentTerms,
    ModalAttributeOfferConsentTermsVue,
    ModalAttributeOfferSubscribeCalendar
  },
  props: {
    dialog: {
      default: false,
      type: Boolean
    },
    attributeOffer: Object,
    registrationOffers: Object,
    volunteering: Object,
    isDeleted: Boolean,
  },
  data() {
    return {
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      volunteeringService: new VolunteeringService(),
      campaignService: new CampaignService(),
      step: 1,
      acceptPreRequirements: false,
      acceptConsentTerm: false,
      selectedPontualTypes: [],
      selectedRecurrentTypes: [],
      selectedRegistrationOffers: [],
      userAlreadyHasRegistrationOffers: false,
      selectedDates: [],
      aggregatedSelectedDates: "",
      googleCalendarRecurrencies: [],
      googleCalendarData: [],
      printTerms: false,
      printscreen: false,
      message: "",
      showMessage: false,
      isDeleted: false,
      campaignId: null,
      campaignName: null,
    };
  },
  async mounted() {
    moment.locale('pt-br');
    await this.getCampaignId()
    this.userAlreadyHasRegistrationOffers = this.registrationOffers > 0;
  },
  computed: {
    ...mapGetters(["logged"]),
    total() {
      return this.selectedRecurrentTypes.length * 1;
    },
    recurrentType() {
      if (this.attributeOffer == null || this.attributeOffer.volunteeringType.name != 'Recorrente') {
        return null;
      }

      var recurrentTypes = this.attributeOffer.volunteeringRecurrentTypes;
      if (recurrentTypes.length == 0)
        return null;

      return recurrentTypes[0];
    },
    times() {
      if (this.recurrentType == null)
        return [];

      var recurrency = this.recurrentType;
      if(moment(recurrency.initialTime).format() != 'Invalid date'){
        recurrency.initialTime = moment(recurrency.initialTime).format("HH:mm")
        recurrency.finalTime = moment(recurrency.finalTime).format("HH:mm")
      }
      //var initialTime = Number(recurrency.initialTime.substr(0, recurrency.initialTime.indexOf(':')));
      //var finalTime = Number(recurrency.finalTime.substr(0, recurrency.finalTime.indexOf(':')));
      var initialTime = 6
      var finalTime = 23

      var range = _.range(initialTime, finalTime, 1);
      var result = [];

      range.forEach((element, index) => {
        var actualHour = moment(initialTime, 'HH:mm').add(index, 'hours');
        result.push({
          index: element,
          hour: actualHour.format('HH:mm')
        });
      });
      return result;
    },
    selectedAttributeOffer() {
      return this.attributeOffer;
    },
    weekDays() {
      var recurrency = this.recurrentType
      var initialTime = parseInt(recurrency.initialTime.toString().replace(':00',''))
      var finalTime = parseInt(recurrency.finalTime.toString().replace(':00',''))
      if (this.recurrentType == null)
        return;

      var weekDays = [
        {
          weekDayName: 'Segunda',
          order: 1,
          abbr: 'S',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        },
        {
          weekDayName: 'Terça',
          order: 2,
          abbr: 'T',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        },
        {
          weekDayName: 'Quarta',
          order: 3,
          abbr: 'Q',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        },
        {
          weekDayName: 'Quinta',
          order: 4,
          abbr: 'Q',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        },
        {
          weekDayName: 'Sexta',
          order: 5,
          abbr: 'S',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        },
        {
          weekDayName: 'Sábado',
          order: 6,
          abbr: 'S',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        },
        {
          weekDayName: 'Domingo',
          order: 7,
          abbr: 'D',
          isDisabled: true,
          initialTime: initialTime,
          finalTime: finalTime
        }
      ];
      this.recurrentType.volunteeringRecurrentWeekDays.forEach(weekDay => {
        weekDays.map((e, index) => {
          if (e.weekDayName === weekDay.weekDayName) {
            weekDays[index].isDisabled = false;
          }
        })
      });

      return weekDays.sort((prev, next) => prev.order - next.order);
    },
  },
  watch: {
    acceptConsentTerm(val) {
      if (val) {
        this.acceptPreRequirements = true
        this.printTerms = true
      }
    },
    dialog(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.acceptConsentTerm = false;
        this.acceptPreRequirements = false;
      }

      if (!newValue && oldValue) {
        this.step = 1;
        this.acceptPreRequirements = false;
        this.acceptConsentTerm = false;
        this.selectedPontualTypes = [];
        this.selectedRecurrentTypes = [];
        this.selectedRegistrationOffers = [];
        this.userAlreadyHasRegistrationOffers = false;
      }
    },
    isDeleted(newValue) {
      this.isDeleted = newValue
    },
    registrationOffers(newValue) {
      this.userAlreadyHasRegistrationOffers = newValue.length > 0;
      if (this.userAlreadyHasRegistrationOffers && !this.isDeleted) {
        this.selectedRegistrationOffers = newValue;

        this.swal('Ops', "Parece que você já possui registro nessa vaga, exibiremos para você os seus dados.", 'info');

        this.findGoogleCalendarRecurrencies();
      }
    },
    async attributeOffer(val) {
      if (val)
        await this.loadVolunteeringInstitution(val)
    }
  },
  methods: {
    swal(title, message, type) {
      CommonHelper.swal(title, message, type);
    },
    close() {
      this.$emit("close");
      this.step = 1;
    },
    next() {
      if (this.step >= 1) {

        if (this.step == 1 && !this.validateStep1()) {
          return;
        }

        if (this.step == 2) {
          if (!this.validateStep2())
            return;

          this.registerOffer();
          return;
        }

        this.step = this.step + 1;
      }
    },
    prev() {
      if (this.step >= 1 && this.step < 3) {
        this.step--;
      }
    },
    validateStep1() {
      let result = this.acceptConsentTerm && this.acceptPreRequirements;

      if (!this.acceptConsentTerm && !this.acceptPreRequirements)
        this.swal('Ops', 'Parece que você esqueceu de aceitar os termos e os pré-requisitos da vaga', 'info');
      else if (!this.acceptConsentTerm)
        this.swal('Ops', 'Parece que você esqueceu de aceitar os termos', 'info');
      else if (!this.acceptPreRequirements) {
        this.swal('Ops', 'Parece que você esqueceu de aceitar os pré-requisitos', 'info');
      }

      return result;
    },
    validateStep2() {
      if (this.attributeOffer.volunteeringType.name == 'Pontual') {
        return this.validateStep2PontualType();
      }

      if (this.attributeOffer.volunteeringType.name == 'Recorrente') {
        return this.validateStep2RecurrentType();
      }

      return false;
    },
    validateStep2PontualType() {
      if (this.selectedPontualTypes.length == 0) {
        this.swal('Por favor', 'Por favor, selecione ao menos uma data no calendário', 'info');
        return false;
      }

      return true;
    },
    validateStep2RecurrentType() {
      if (this.total < this.recurrentType.minHoursDedication) {
        this.swal('Por favor', 'Parece que a disponibilidade informada não atende o número mínimo de horas permitidas', 'info');
        return false;
      }
      return true;
    },
    print(value) {
      if (value && this.printscreen) {
        this.printscreen = false;
        setTimeout(() => { this.printscreen = true }, 500);
      }
      this.printscreen = value;
    },
    isSelected(day, time) {
      if (this.selectedRegistrationOffers.length > 0) {
        return this.selectedRegistrationOffers.some(registration => {
          return registration.volunteeringRegistrationOfferWeekDayHours.some(weekDayHours => {
            return weekDayHours.weekDayName == day
              && moment(weekDayHours.dayHour).format('HH:mm') == time;
          })
        });
      }
      return false;
    },
    async loadVolunteeringInstitution(value) {
      if (!value.volunteeringId)
        return;
      
      const institution = await this.volunteeringService.findInstitutionByVolunteeringId(value.volunteeringId)
      value.institution = institution
    },
    getRecurrentLimitDateText(recurrentType) {
      return moment(recurrentType.volunteeringDateUntil).format('DD/MM/YYYY');
    },
    getPontualTypeDateText(pontualType) {
      return moment(pontualType.volunteeringDate).format('DD/MM/YYYY');
    },
    getRecurrentWeekHoursText(recurrentType) {
      if (recurrentType.minHoursDedication
        && recurrentType.maxHoursDedication
        && recurrentType.minHoursDedication != recurrentType.maxHoursDedication)
        return `de ${recurrentType.minHoursDedication} a ${recurrentType.maxHoursDedication} horas`;

      if (recurrentType.minHoursDedication)
        return `${recurrentType.minHoursDedication} horas`;

      if (recurrentType.maxHoursDedication)
        return `${recurrentType.maxHoursDedication} horas`;

      return "Não definido";
    },
    getPontualTypeMonth(date) {
      return moment(date).format('MMM');
    },
    getPontualTypeDay(date) {
      return moment(date).format('DD');
    },
    getLocaleAwareDate(date) {
      return moment(date).format('LL');
    },
    getPontualTypeWeekDay(date) {
      return moment(date).format('dddd');
    },
    getPontualTypeDateTimeText(pontualType) {
      let volunteeringDate = moment(pontualType.volunteeringDate, 'YYYY-MM-DDTHH:mm:ss');
      let initial = moment(pontualType.initialTime, 'YYYY-MM-DDTHH:mm:ss');
      let final = moment(pontualType.finalTime, 'YYYY-MM-DDTHH:mm:ss');

      return `${volunteeringDate.format('DD/MM/YYYY')} das ${initial.format('HH:mm')} às ${final.format('HH:mm')}`
    },
    openConsentTerm() {
     
      
    },
    selectPontualType(pontualType) {
      var index = this.selectedPontualTypes.findIndex(date => date.id == pontualType.id);
      if (index != -1)
        this.selectedPontualTypes.splice(index, 1);
      else
        this.selectedPontualTypes.push(pontualType);
    },
    registerOffer() {
      if (this.attributeOffer.volunteeringType.name == 'Pontual' && this.isDeleted) {
        this.registerPontualOffer();
      }

      if (this.attributeOffer.volunteeringType.name == 'Recorrente' && this.isDeleted) {
        this.registerRecurrentOffer();
      }

      if (this.attributeOffer.volunteeringType.name == 'Pontual' && !this.isDeleted) {
        this.registerPontualOffer();
      }

      if (this.attributeOffer.volunteeringType.name == 'Recorrente' && !this.isDeleted) {
        this.registerRecurrentOffer();
      }
    },
    registerPontualOffer() {
      this.selectedPontualTypes = this.injectData(this.selectedPontualTypes)
      this.volunteeringRegistrationOfferService.registerPontualOffer(this.selectedPontualTypes)
        .then(async data => {
          if (data != undefined) {
            this.step = this.step + 1;
            this.selectedRegistrationOffers = data;
            this.userAlreadyHasRegistrationOffers = true;
            await this.$parent.loadVolunteering();
          }
        });
    },
    registerRecurrentOffer() {
      this.selectedRecurrentTypes = this.injectData(this.selectedRecurrentTypes)
      this.volunteeringRegistrationOfferService.registerRecurrentOffer(this.selectedRecurrentTypes)
        .then(async data => {
          if (data != undefined) {
            this.step = this.step + 1;
            this.selectedRegistrationOffers = data;
            this.userAlreadyHasRegistrationOffers = true;
            this.findGoogleCalendarRecurrencies();
            await this.$parent.reloadCards();
          }
        });
    },
    findGoogleCalendarRecurrencies() {
      this.volunteeringRegistrationOfferService.findUserRegistrationRecurrencyGoogleCalendar(this.attributeOffer.id)
        .then(data => {
          if (data != undefined) {
            this.googleCalendarRecurrencies = data.groups;
            this.createGoogleCalendarUrlRecurrent()
          }
        });
    },
    checkMaxHours(e) {

      if (e.target && !e.target.checked)
        return true;

      if (this.total == this.recurrentType.maxHoursDedication) {
        this.swal('Ops', 'Parece que você atingiu o número máximo de horas permitidas.', 'info');
        e.preventDefault();
      }
    },
    openGoogleCalendar() {

    },
    createGoogleCalendarUrlPontual(registration) {
      let baseurl = 'https://calendar.google.com/calendar/render';

      let action = 'action=TEMPLATE';

      let eventTitle = 'text=EVENT_TITLE';
      let eventDetails = 'details=EVENT_DETAILS';
      let dates = 'dates=SELECTED_DATES';
      let timeZone = 'ctz=America/Sao_Paulo';

      eventTitle = eventTitle.replace('EVENT_TITLE', this.attributeOffer.offerName);
      eventDetails = eventDetails.replace('EVENT_DETAILS', this.attributeOffer.function);


      var selectedDates = [];
      let volunteeringDate = moment(registration.volunteeringPontualType.volunteeringDate, 'YYYY-MM-DDTHH:mm:ss').format('YYYYMMDD');
      let initial = moment(registration.volunteeringPontualType.initialTime, 'YYYY-MM-DDTHH:mm:ss').format('HHmmss');
      let final = moment(registration.volunteeringPontualType.finalTime, 'YYYY-MM-DDTHH:mm:ss').format('HHmmss');

      selectedDates.push(volunteeringDate + 'T' + initial);
      selectedDates.push(volunteeringDate + 'T' + final);

      this.selectedDates = selectedDates;
      var aggregatedSelectedDates = selectedDates.reduce((prev, current) => prev + '/' + current);
      this.aggregatedSelectedDates = aggregatedSelectedDates;
      dates = dates.replace('SELECTED_DATES', aggregatedSelectedDates);

      return `${baseurl}?${action}&${eventTitle}&${eventDetails}&${dates}&${timeZone}`;
    },
    createGoogleCalendarUrlRecurrent() {
      var baseurl = 'https://calendar.google.com/calendar/render';

      var action = 'action=TEMPLATE';

      var eventTitle = 'text=EVENT_TITLE';
      var eventDetails = 'details=EVENT_DETAILS';
      var dates = 'dates=SELECTED_DATES';
      var timeZone = 'ctz=America/Sao_Paulo';
      var reccur = 'recur=RRULE:FREQ=WEEKLY;UNTIL=DATE_LIMIT;BYDAY=WEEK_DAYS';

      if (this.attributeOffer) {
        eventTitle = eventTitle.replace('EVENT_TITLE', this.attributeOffer.offerName);
        eventDetails = eventDetails.replace('EVENT_DETAILS', this.attributeOffer.function);
      }

      if (this.recurrentType) {
        let DATE_LIMIT = moment(this.recurrentType.volunteeringDateUntil, 'YYYY-MM-DD').format('YYYYMMDDTHHmmss')
        reccur = reccur.replace('DATE_LIMIT', DATE_LIMIT);
      }
      this.googleCalendarData = [];
      for (let index = 0; index < this.googleCalendarRecurrencies.length; index++) {
        let selectedDates = [];
        let volunteeringDate = moment().format('YYYYMMDD');
        let initial = moment(this.googleCalendarRecurrencies[index].initialHour, 'HH:mm').format('HHmmss');
        let final = moment(this.googleCalendarRecurrencies[index].finalHour, 'HH:mm').format('HHmmss');

        selectedDates.push(volunteeringDate + 'T' + initial);
        selectedDates.push(volunteeringDate + 'T' + final);

        let aggregatedSelectedDates = selectedDates.reduce((prev, current) => prev + '/' + current);
        dates = dates.replace('SELECTED_DATES', aggregatedSelectedDates);

        let reccur_instance = reccur.replace('WEEK_DAYS', this.getRFC5545WeekDays(this.googleCalendarRecurrencies[index].weekDayNames));

        let url = `${baseurl}?${action}&${eventTitle}&${eventDetails}&${dates}&${timeZone}&${reccur_instance}`;

        this.googleCalendarData.push({
          url: url,
          weekDays: this.googleCalendarRecurrencies[index].weekDayNames.reduce((prev, current) => prev + ', ' + current),
          initialTime: this.googleCalendarRecurrencies[index].initialHour,
          finalTime: this.googleCalendarRecurrencies[index].finalHour
        });
      }
    },
    getRFC5545WeekDays(weekDayNames) {

      //SU,MO,TU,WE,TH,FR,SA
      let rfc5545 = {};
      rfc5545['Domingo'] = 'SU';
      rfc5545['Segunda'] = 'MO';
      rfc5545['Terça'] = 'TU';
      rfc5545['Quarta'] = 'WE';
      rfc5545['Quinta'] = 'TH';
      rfc5545['Sexta'] = 'FR';
      rfc5545['Sábado'] = 'SA';

      return weekDayNames.map(week => rfc5545[week]).reduce((prev, current) => prev + ',' + current);
    },
    async getCampaignId() {
      const data = await this.campaignService.findBySubdomain()
      this.campaignId = data.id
      this.campaignName = data.name
    },
    getDate(date) {
      if(/^\d{2}:\d{2}$/.test(date)) {
        return date
      }
      return moment(date).format('HH:mm')
    },
    injectData(items) {
      const uid = UIDHelper.create()
      for (const item of items) {
        item.volunteeringUID = uid
        item.campaignId = this.campaignId
        item.campaignName = this.campaignName
      }
      return items;
    },
  },
};
</script>

<style>
</style>